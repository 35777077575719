<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="mstTable"
          title="기계/장비유형 목록"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="mstGrid.columns"
          :isExcelDown="false"
          :data="mstGrid.data"
          :selection="popupParam.type"
          rowKey="code"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="선택" icon="check" @btnClicked="select" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'project-manage',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
  },
  data() {
    return {
      mstGrid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '기계장비유형',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      selectedRows: {}
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$comm.getComboItems('HHM_TYPE_CD').then(_result => {
        _result = this.$_.filter(_result, {useFlag : 'Y' });
        this.mstGrid.data = _result
      })
    },
    select() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '기계/장비 유형을 지정하세요.', // 기계/장비 유형을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
