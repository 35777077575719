var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "mstTable",
              attrs: {
                title: "기계/장비유형 목록",
                tableId: "mstTable",
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                columns: _vm.mstGrid.columns,
                isExcelDown: false,
                data: _vm.mstGrid.data,
                selection: _vm.popupParam.type,
                rowKey: "code",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "선택", icon: "check" },
                        on: { btnClicked: _vm.select },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }